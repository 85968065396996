
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import type { ElForm } from "element-plus";

type FormInstance = InstanceType<typeof ElForm>;

export default defineComponent({
  components: {
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("数据统计", ["数据统计"])
    })

    const formRef = ref<FormInstance>();
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const summary = ref({})

    store
      .dispatch(Actions.GET_SUMMARY)
      .then(() => {
        summary.value = store.getters.currentSummary
      })
      .catch(() => {
        // const [error] = Object.keys(store.getters.getErrors);
        // Swal.fire({
        //   text: store.getters.getErrors[error],
        //   icon: "error",
        //   buttonsStyling: false,
        //   confirmButtonText: "Try again!",
        //   customClass: {
        //     confirmButton: "btn fw-bold btn-light-danger",
        //   },
        // })
      })

    const water_list = ref({})

    const water_submit = () => {
      let params = {
        from: `${water_duration.value[0].toLocaleDateString()} ${water_duration.value[0].getHours()}:${water_duration.value[0].getMinutes()}:${water_duration.value[0].getSeconds()}`,
        to: `${water_duration.value[1].toLocaleDateString()} ${water_duration.value[1].getHours()}:${water_duration.value[1].getMinutes()}:${water_duration.value[1].getSeconds()}`,
      }

      store
        .dispatch(Actions.GET_WATER_LIST, params)
        .then(() => {
          water_list.value = store.getters.currentWaterList
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // })
        })
    }

    const loser_list = ref({})

    const loser_submit = () => {
      let params = {
        from: `${loser_duration.value[0].toLocaleDateString()} ${loser_duration.value[0].getHours()}:${loser_duration.value[0].getMinutes()}:${loser_duration.value[0].getSeconds()}`,
        to: `${loser_duration.value[1].toLocaleDateString()} ${loser_duration.value[1].getHours()}:${loser_duration.value[1].getMinutes()}:${loser_duration.value[1].getSeconds()}`,
      }

      store
        .dispatch(Actions.GET_LOSER_LIST, params)
        .then(() => {
          loser_list.value = store.getters.currentLoserList
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // })
        })
    }

    let date = new Date()
    let from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)
    let to = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)

    const water_duration = ref([
      from,
      to,
    ])

    const loser_duration = ref([
      from,
      to,
    ])

    water_submit()
    loser_submit()

    return {
      summary,
      water_list,
      water_submit,
      water_duration,
      loser_list,
      loser_submit,
      loser_duration
    }
  },
})
